.construction {
  display: block;
  margin: auto;
  width: 80vw;
  max-width: 622px;
}

.blink {
  display: block;
  text-align: center;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}