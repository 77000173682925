body {
  margin: 0;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-family: sans-serif;
}


.construction {
  display: block;
  margin: auto;
  width: 80vw;
  max-width: 622px;
}

.blink {
  display: block;
  text-align: center;
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
